// reportApi.js
export const saveReport = async (data, token) => {
    console.log("saving report", data);
   
    const response = await fetch(`${process.env.REACT_APP_API_BACKEND_APIM_URL}reports/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_INTERNAL_USER_SUBSCRIPTION_KEY,
            Authorization: `Bearer ${token}`, // Pass the token as an argument
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Failed to create report: ${response.status} - ${errorDetails.message}`);
    }

    return await response.json();
};

//this needs 
export const getReport = async (reportId, token) => {
    console.log(`fetching report: ${reportId}`);
   
    const response = await fetch(`${process.env.REACT_APP_API_BACKEND_APIM_URL}reports/${reportId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_INTERNAL_USER_SUBSCRIPTION_KEY,
            Authorization: `Bearer ${token}`, // Pass the token as an argument
        },
    });

    if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Failed to fetch report: ${response.status} - ${errorDetails.message}`);
    }
    return await response.json();
};
