import React, { useState } from "react";
import { Form, Button, Alert, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useOrganisation from "../hooks/useOrganisation";
import { useToken } from "../context/TokenContext"; // Import TokenContext
import { useDispatch } from "react-redux";
import { updateUserOrganisationId } from "../redux/slices/userSlice";

const CreateOrganisation = () => {
    const [companyName, setCompanyName] = useState("");
    const [buildingNumber, setBuildingNumber] = useState("");
    const [postcode, setPostcode] = useState("");
    const [country, setCountry] = useState("");
    const [phone, setPhone] = useState("");
    const [website, setWebsite] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { create } = useOrganisation();
    const { token, loading: tokenLoading, error: tokenError } = useToken(); 
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const organisationData = {
            companyName,
            buildingNumber,
            postcode,
            country,
            phone,
            website,
        };
        try {
            setLoading(true);
            if (!token) {
                throw new Error("Token is not available yet.");
            }
            const response = await create(organisationData, token); // Pass the token from TokenContext
            dispatch(updateUserOrganisationId(response.id));
            navigate(`/organisations/${response.id}/dashboard`);
        } catch (error) {
            console.error("Error creating organisation:", error);
            setError("There was an issue creating your organisation. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    if (tokenLoading) return <div>Loading token...</div>; // Show loading state if token is still being fetched

    if (tokenError) return <div>Error fetching token: {tokenError}</div>; // Show error if token fetch fails

    return (
        <Container className="p-3">
            <h2>Create new Organisation</h2>
            <Row className="justify-content-center">
                <Col>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="companyName">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter company name"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="buildingNumber" className="mt-3">
                            <Form.Label>Building Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter building number"
                                value={buildingNumber}
                                onChange={(e) => setBuildingNumber(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="postcode" className="mt-3">
                            <Form.Label>Postcode</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter postcode"
                                value={postcode}
                                onChange={(e) => setPostcode(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="country" className="mt-3">
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="phone" className="mt-3">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter phone number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="website" className="mt-3">
                            <Form.Label>Website</Form.Label>
                            <Form.Control
                                type="url"
                                placeholder="Enter website"
                                value={website}
                                onChange={(e) => setWebsite(e.target.value)}
                            />
                        </Form.Group>

                        <Button type="submit" variant="primary" className="mt-3" disabled={loading || tokenLoading} block="true">
                            {loading || tokenLoading ? "Creating..." : "Create Organisation"}
                        </Button>
                        {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default CreateOrganisation;
