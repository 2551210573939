import React from "react";
import { useSelector } from "react-redux";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useUserAccount from "../hooks/useUserAccount";

const HomePage = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useUserAccount();
    const userData = useSelector((state) => state.user.userData);

    const handleCreateOrganisation = () => {
        navigate("/organisations/create");
    };

    const handleGetStarted = (tier) => {
        alert(`Get started with the ${tier} plan!`);
    };

    const handleLearnMore = (tier) => {
        alert(`Learn more about the ${tier} plan.`);
    };

    return (
        <Container fluid className="mt-4">
            {/* Full-width card for portal description */}
            <Card className="mb-4 shadow-lg p-4">
                <Card.Body>
                    <Card.Title className="text-center display-4">Welcome to Australis</Card.Title>
                    <Card.Text className="text-center">
                        Australis is a renewable energy feasibility report generator that leverages AI to streamline project management.
                        By combining advanced geospatial calculations, AI-driven analysis, and comprehensive reporting tools, Australis empowers
                        you to make informed, data-driven decisions at every stage of your energy projects. Explore our subscription plans to find
                        the right fit for your goals.
                    </Card.Text>
                </Card.Body>
            </Card>

            {/* Create Organisation Button */}
            {isAuthenticated() && userData && userData.organisationId == null && (
                <div className="text-center mb-4">
                    <Button variant="success" size="lg" onClick={handleCreateOrganisation}>
                        Create Organisation
                    </Button>
                </div>
            )}

            {/* Subscription Tiers */}
            <Row className="g-4">
                {/* Basic Tier */}
                <Col md={4}>
                    <Card className="h-100 shadow border-primary p-4">
                        <Card.Body className="d-flex flex-column">
                            <Card.Title className="text-primary">Basic (Free)</Card.Title>
                            <Card.Text className="flex-grow-1">
                                <strong>Features:</strong>
                                <ul>
                                    <li>Limited access to site feasibility insights</li>
                                    <li>Basic planning considerations</li>
                                    <li>Basic collaboration assessment</li>
                                    <li>Limited engineering design and modelling</li>
                                </ul>
                                <strong>Additional Details:</strong>
                                <ul>
                                    <li>Net developable area insights</li>
                                    <li>Basic energy yield prediction</li>
                                </ul>
                            </Card.Text>
                            <Button variant="primary" onClick={() => handleGetStarted("Basic")} className="mt-auto">
                                Get Started
                            </Button>
                            <Button variant="link" onClick={() => handleLearnMore("Basic")} className="mt-2">
                                Learn More
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Pro Tier */}
                <Col md={4}>
                    <Card className="h-100 shadow border-warning bg-light p-4">
                        <Card.Body className="d-flex flex-column">
                            <Card.Title className="text-warning">Pro (Paid)</Card.Title>
                            <Card.Text className="flex-grow-1">
                                <strong>Features:</strong>
                                <ul>
                                    <li>Full access to site feasibility insights</li>
                                    <li>Comprehensive planning considerations with reporting</li>
                                    <li>Advanced collaboration assessment</li>
                                    <li>Advanced engineering design and modelling</li>
                                </ul>
                                <strong>Additional Details:</strong>
                                <ul>
                                    <li>Net developable area insights</li>
                                    <li>Advanced energy yield prediction</li>
                                    <li>Optimized 3D project design</li>
                                </ul>
                            </Card.Text>
                            <Button variant="warning" onClick={() => handleGetStarted("Pro")} className="mt-auto">
                                Get Started
                            </Button>
                            <Button variant="link" onClick={() => handleLearnMore("Pro")} className="mt-2">
                                Learn More
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Enterprise Tier */}
                <Col md={4}>
                    <Card className="h-100 shadow border-danger bg-light p-4">
                        <Card.Body className="d-flex flex-column">
                            <Card.Title className="text-danger">Enterprise (Custom)</Card.Title>
                            <Card.Text className="flex-grow-1">
                                <strong>Features:</strong>
                                <ul>
                                    <li>All features from Pro tier</li>
                                    <li>Comprehensive planning considerations with reporting</li>
                                    <li>Advanced collaboration assessment with customization</li>
                                    <li>Comprehensive engineering design and modelling</li>
                                </ul>
                                <strong>Additional Details:</strong>
                                <ul>
                                    <li>Detailed construction and EPC planning</li>
                                    <li>Tailored for enterprise-level requirements</li>
                                    <li>Dedicated support and training</li>
                                </ul>
                            </Card.Text>
                            <Button variant="danger" onClick={() => handleGetStarted("Enterprise")} className="mt-auto">
                                Get Started
                            </Button>
                            <Button variant="link" onClick={() => handleLearnMore("Enterprise")} className="mt-2">
                                Learn More
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default HomePage;
