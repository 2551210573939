let interactionInProgress = false; // Global lock for interaction status

export const getToken = async (instance) => {
    const activeAccount = instance.getActiveAccount(); // Get the active account

    if (!activeAccount) {
        console.warn("No active account found.");
        return null;
    }

    const request = {
        scopes: [`https://australisenergyb2corg.onmicrosoft.com/${process.env.REACT_APP_B2C_API_CLIENT_ID}/access_as_user`],
        account: activeAccount, // Use the active account for the request
    };

    try {
        const response = await instance.acquireTokenSilent(request);
        return response.accessToken; // Return the access token
    } catch (error) {
        console.error("Token acquisition failed", error);

        // Handle interaction required error
        if (error.name === "InteractionRequiredAuthError") {
            if (interactionInProgress) {
                console.warn("An interaction is already in progress.");
                return null; // Prevent overlapping interactions
            }

            interactionInProgress = true; // Lock interactions
            try {
                const response = await instance.acquireTokenPopup(request);
                return response.accessToken;
            } catch (popupError) {
                console.error("Popup token acquisition failed:", popupError);

                // Fallback to redirection if popup fails
                console.warn("Falling back to redirect for token acquisition.");
                await instance.acquireTokenRedirect(request);
                return null; // Redirection doesn't return a token immediately
            } finally {
                interactionInProgress = false; // Unlock interactions
            }
        }

        return null; // Return null for other errors
    }
};
