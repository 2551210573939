// userAPi.js
export const saveUserData = async (token, userData) => {
    console.log(`${process.env.REACT_APP_API_BACKEND_APIM_URL}users/save`);
    const response = await fetch(`${process.env.REACT_APP_API_BACKEND_APIM_URL}users/save`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_INTERNAL_USER_SUBSCRIPTION_KEY,
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
            oid: userData.oid,  // Object ID (should come from the authentication response)
            name: userData.name,
            email: userData.email
        })
    });

    if (!response.ok) {
        throw new Error(`Failed to save user data: ${response.statusText}`);
    }

   // Read the response body only once
   const responseData = await response.json();
   return responseData;
};
