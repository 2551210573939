import React, { useState } from 'react';
import { Card, Modal, Button, Form, Alert } from 'react-bootstrap';
import { deleteProject } from '../../api/projectApi'; // Import the deleteProject API function
import { useToken } from '../../context/TokenContext'; // Use TokenContext for token
import { useNavigate } from 'react-router-dom';

export default function ProjectAdmin({ project }) {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [projectNameInput, setProjectNameInput] = useState('');
    const [isDeleteEnabled, setIsDeleteEnabled] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false); // State for API call status
    const [alertMessage, setAlertMessage] = useState(null); // State for alert messages
    const [alertVariant, setAlertVariant] = useState(''); // State for alert variant
    const { token } = useToken(); // Access the token from context
    const navigate = useNavigate(); // To navigate after deletion

    // Open and close modal handlers
    const handleOpenModal = () => setShowDeleteModal(true);
    const handleCloseModal = () => {
        setShowDeleteModal(false);
        setProjectNameInput('');
        setIsDeleteEnabled(false);
        setAlertMessage(null); // Clear alerts when modal is closed
    };

    // Handle project deletion
    const handleDelete = async () => {
        if (!project?.id || !token) {
            setAlertMessage('Missing project ID or authentication token.');
            setAlertVariant('danger');
            return;
        }

        setIsDeleting(true); // Show loading state
        try {
            await deleteProject(project.id, token); // Pass token to the delete API
            setAlertMessage('Project deleted successfully!');
            setAlertVariant('success');
            handleCloseModal();
            navigate(`/organisations/${project.organisationId}/dashboard`); // Redirect to project list
        } catch (error) {
            setAlertMessage(`Failed to delete project: ${error.message}`);
            setAlertVariant('danger');
        } finally {
            setIsDeleting(false); // Reset loading state
        }
    };

    return (
        <Card>
            <Card.Body>
                <Card.Header>Settings</Card.Header>

                {/* Delete Project Button */}
                <Button variant="danger" className="mt-3" onClick={handleOpenModal}>
                    Delete this project
                </Button>

                {/* Delete Confirmation Modal */}
                <Modal show={showDeleteModal} onHide={handleCloseModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Project Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Display alert within the modal */}
                        {alertMessage && (
                            <Alert variant={alertVariant} className="mt-2">
                                {alertMessage}
                            </Alert>
                        )}

                        <p>
                            To confirm deletion, please type the name of the project: <strong>{project?.name}</strong>
                        </p>
                        <p className="text-danger fw-bold">
                            Warning: This action will permanently delete this project and all related data. This cannot be undone.
                        </p>
                        <Form>
                            <Form.Group className="mb-3" controlId="projectNameInput">
                                <Form.Label>Project Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter project name"
                                    value={projectNameInput}
                                    onChange={(e) => {
                                        setProjectNameInput(e.target.value);
                                        setIsDeleteEnabled(e.target.value === project?.name);
                                    }}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button
                            variant="danger"
                            disabled={!isDeleteEnabled || isDeleting}
                            onClick={handleDelete}
                        >
                            {isDeleting ? 'Deleting...' : 'Delete'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Card.Body>
        </Card>
    );
}
