import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { getToken as fetchNewToken } from '../utils/authUtils'; // Import the getToken function
import { useMsal } from "@azure/msal-react"; // Import MSAL instance from msal-react

export const TokenContext = createContext();

// Hook to access the token
export const useToken = () => {
    return useContext(TokenContext);
};

// Helper to parse token expiration
const getTokenExpiryTime = (token) => {
    if (!token) return null;

    try {
        const payloadBase64 = token.split('.')[1]; // Extract the payload
        const payload = JSON.parse(atob(payloadBase64)); // Decode Base64 and parse JSON
        return payload.exp ? payload.exp * 1000 : null; // Convert `exp` to milliseconds
    } catch (error) {
        console.error("Failed to decode token payload:", error);
        return null;
    }
};

// Provider component to wrap the app and provide the token
export const TokenProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { instance } = useMsal(); // Get the MSAL instance from msal-react

    const fetchToken = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const newToken = await fetchNewToken(instance); // Pass the MSAL instance to getToken
            if (!newToken) {
                console.warn("User is not authenticated");
                setToken(null); // Ensure token is null if no token is returned
            } else {
                setToken(newToken); // Set the token if it exists

                // Decode the token payload to log user ID
                const payloadBase64 = newToken.split('.')[1];
                const payload = JSON.parse(atob(payloadBase64));
                const userId = payload.sub || payload.unique_name || payload.oid; // Adjust based on your token claims
                console.log("User ID:", userId);
            }
        } catch (err) {
            console.error("Error fetching token: ", err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [instance]);


    // Proactively refresh the token before it expires
    useEffect(() => {
        if (token) {
            const expiryTime = getTokenExpiryTime(token);
            if (expiryTime) {
                const refreshBuffer = 5 * 60 * 1000; // Refresh 5 minutes before expiration
                const timeUntilRefresh = expiryTime - Date.now() - refreshBuffer;

                const timerId = setTimeout(() => {
                    fetchToken(); // Refresh the token
                }, Math.max(timeUntilRefresh, 0));

                return () => clearTimeout(timerId); // Cleanup on unmount
            }
        }
    }, [token, fetchToken]);

    // Fetch the token when the app initializes
    useEffect(() => {
        if (!token) {
            fetchToken();
        }
    }, [token, fetchToken]);

    return (
        <TokenContext.Provider value={{ token, loading, error, fetchToken }}>
            {children}
        </TokenContext.Provider>
    );
};
