import * as atlas from 'azure-maps-control';
import { wktToGeoJSON } from '@terraformer/wkt';


export const setCameraView = (map, polygonDatasource) => {
    const features = polygonDatasource.getShapes();
    if (features.length > 0) {
        const boundingBox = atlas.data.BoundingBox.fromData(features);
        if (boundingBox) {
            map.setCamera({
                bounds: boundingBox,
                padding: 50
            });
        }
    }
};

export const getCentroidByLayerId = (layerId, polygonDatasource) => {
    // Get the shape by layerId
    const shapes = polygonDatasource.getShapes();
    
    // Find the shape matching the given layerId
    const shape = shapes.find(s => s.getId() === layerId);
    
    if (shape) {
        const geometry = shape.toJson().geometry;

        // Check if the geometry is a Polygon and compute centroid
        if (geometry && geometry.type === 'Polygon') {
            const coordinates = geometry.coordinates[0]; // Get the outer ring coordinates
            const centroid = calculatePolygonCentroid(coordinates);
            return centroid;
        }
    }
    return null;
};

export const calculatePolygonCentroid = (coordinates) => {
    let xSum = 0, ySum = 0, totalPoints = coordinates.length;
    
    coordinates.forEach(coord => {
        xSum += coord[0]; // X coordinate (longitude)
        ySum += coord[1]; // Y coordinate (latitude)
    });
    
    const centroidX = xSum / totalPoints;
    const centroidY = ySum / totalPoints;
    
    return [centroidX, centroidY]; // Return the centroid as an [x, y] array
};


export const drawLine = (pointA, pointB, lineDatasource, map) => {
    try {
        // Validate points
        if (!pointA || !pointB || pointA.length !== 2 || pointB.length !== 2) {
            console.error("Invalid points provided. Expected two [longitude, latitude] coordinates.");
            return;
        }

        // Create a line between pointA and pointB
        const line = new atlas.data.LineString([pointA, pointB]);
        const lineFeature = new atlas.data.Feature(line);

        // Add the line to the datasource
        lineDatasource.add(lineFeature);

        // Ensure a line layer exists and add it if not
        const existingLayers = map.layers.getLayers();
        const lineLayerId = "constraint-line-layer";

        if (!existingLayers.some(layer => layer.getId() === lineLayerId)) {
            const lineLayer = new atlas.layer.LineLayer(lineDatasource, lineLayerId, {
                strokeColor: '#000000',
                strokeWidth: 2,
                strokeOpacity: 1,
                strokeLineJoin: 'round',
                strokeDashArray: [1, 4], // Dash pattern: 4 pixels dash, 2 pixels gap
            });

            map.layers.add(lineLayer);
        }

    } catch (error) {
        console.error("Error drawing line:", error);
    }
};



export const AddGeometry = (map, polygonDatasource, constraint) => {
    try {
        if (!constraint || !constraint.geometry || !constraint.layerRGBValue) {
            console.error("Invalid geometry data provided.");
            return null; // No centroid can be returned
        }

        console.log("AddGeometry.constraint", constraint);

        const { geometry, layerRGBValue, calculatorName, closestLocation, score } = constraint;

        // Parse WKT geometry into GeoJSON
        const geoJson = wktToGeoJSON(geometry); // Convert WKT to GeoJSON

        // Compute centroid if the geometry is a Polygon or MultiPolygon
        let centroid = null;
        if (geoJson.type === "Polygon" || geoJson.type === "MultiPolygon") {
            const coordinates = geoJson.type === "Polygon"
                ? geoJson.coordinates[0] // Outer ring of the Polygon
                : geoJson.coordinates[0][0]; // Outer ring of the first Polygon in MultiPolygon
            centroid = calculatePolygonCentroid(coordinates);
        }

        if (!centroid) {
            console.error("Failed to compute centroid for geometry.");
            return null;
        }

        // Add GeoJSON as a feature to the datasource
        const featureId = `${constraint.reportId}`;
        const feature = new atlas.data.Feature(
            geoJson,
            {
                reportId: constraint.reportId,
                calculatorName,
                closestLocationName: closestLocation.name,
                score,
            },
            featureId // Unique ID for the geometry
        );

        polygonDatasource.add([feature]);

        // Create and add a new layer for the geometry
        const geometryLayer = new atlas.layer.PolygonLayer(polygonDatasource, null, {
            fillColor: layerRGBValue,
            strokeWidth: 2,
            strokeColor: '#FFFFFF',
            strokeOpacity: 0.8,
        });

        map.layers.add(geometryLayer);

        // Create a custom HTML marker
        const markerHtml = `
            <div style="background: rgba(255, 255, 255, 0.9); border-radius: 6px; padding: 10px; box-shadow: 0 2px 6px rgba(0,0,0,0.3); text-align: center; width: 150px;">
                <div style="font-size: 14px; font-weight: bold;">${calculatorName}</div>
                <div style="font-size: 12px; color: gray;">${closestLocation.name}</div>
                <div style="font-size: 16px; color: #000; margin-top: 5px;"><b>Score: ${score}</b></div>
            </div>
        `;

        const marker = new atlas.HtmlMarker({
            position: new atlas.data.Position(centroid[0], centroid[1]), // [longitude, latitude]
            htmlContent: markerHtml,
            anchor: 'center',
        });

        map.markers.add(marker);

        // Return the computed centroid
        return centroid;
    } catch (error) {
        console.error("Error adding geometry to the map:", error);
        return null; // Indicate failure to compute centroid
    }
};





export const addLayer = (data, map, polygonDatasource) => {
    try {
        const layerId = data.layerId;

        let geoJsonObject;      

        geoJsonObject = JSON.parse(data.value);

        if (!map || !map.layers) {
            console.warn("Map or layers are missing.");
            return;
        }

        const existingLayers = map.layers.getLayers();
        if (existingLayers.some(layer => layer.getId() === data.layerId)) {
            return;
        }

        if (geoJsonObject && geoJsonObject.type === 'FeatureCollection') {
            geoJsonObject.features.forEach((feature, index) => {
                feature.id = `${layerId}-${index}`;  // Add layerId to each feature
            });
            polygonDatasource.add(geoJsonObject);
        } else {
            console.error("Invalid GeoJSON structure", geoJsonObject);
            return;
        }

        const polygonLayer = new atlas.layer.PolygonLayer(polygonDatasource, data.layerId, {
            fillColor: data.colour,
            strokeWidth: 2,
            strokeOpacity: 0.8
        });

        map.layers.add(polygonLayer, 'labels');
    } catch (error) {
        console.error(`Error processing GeoJSON for layer: ${data.layerId}`, error);
    }
};