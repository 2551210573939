import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjects, createProject } from '../redux/slices/projectSlice'; // Import the thunks
import { useToken } from '../context/TokenContext'; // Import the useToken hook
import { Row, Col, Container, Card, Button, Alert, Form, Spinner, Badge } from 'react-bootstrap'; // Import Bootstrap components
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import { Folder2Open, Download, FilePdfFill, ArrowBarRight } from "react-bootstrap-icons";
import moment from 'moment';

const Dashboard = () => {
    const { organisationId } = useParams(); // Get the organisationId from the URL
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Initialize the navigate hook

    // Selectors for projects, loading, and error states
    const projects = useSelector((state) => state.project?.projects || []);
    const loading = useSelector((state) => state.project?.loading || false);
    const error = useSelector((state) => state.project?.error || null);
    const { token } = useToken(); // Fetch token here

    console.log("Dashboard Projects", projects);

    // State for form inputs and status
    const [newProjectName, setNewProjectName] = useState('');
    const [successMessage, setSuccessMessage] = useState(null);
    const [formError, setFormError] = useState(null);
    const [projectCreating, setProjectCreating] = useState(false); // New state to track project creation status

    useEffect(() => {
        if (organisationId && token) {
            dispatch(fetchProjects({ organisationId, token })); // Pass token to thunk
        }
    }, [dispatch, organisationId, token]);

    const handleCreateProject = async (e) => {
        e.preventDefault();
    
        // Validate form fields
        if (!newProjectName) {
            setFormError("Project name is required.");
            return;
        }
    
        // Clear form error and enable project creation state
        setFormError(null);
        setProjectCreating(true);
    
        try {
            // Dispatch createProject action and get the result
            const result = await dispatch(createProject({
                projectData: { OrganisationId: organisationId, Name: newProjectName },
                token, // Pass the token for authorization
            }));
    
            // Check if the result contains an error
            if (result.error) {
                console.error("Failed to create project:", result.error.message);
                setFormError(`Failed to create project: ${result.error.message}`);
                setProjectCreating(false); // Disable project creation state on error
                return; // Exit early since the API call failed
            }
    
            const createdProject = result.payload; // Access the newly created project from the result
            const projectId = createdProject?.id; // Extract the projectId from the payload
    
            // Clear the input after successful creation
            setNewProjectName('');
            setSuccessMessage("Project created successfully!");
            setProjectCreating(false); // Disable project creation state
    
            // Navigate to the newly created project's page
            if (projectId) {
                navigate(`/organisations/${organisationId}/projects/${projectId}`);
            }
        } catch (err) {
            console.error("Failed to create project:", err);
            setFormError("Failed to create project. Please try again.");
            setProjectCreating(false); // Disable project creation state on error
        }
    };
    

    const handleNavigate = (url) => {
        window.location.href = url; // Navigate to project detail page
    };

    return (
        <Container fluid>
            <Row>
                <Col className='m-2'>
                    <h2>Dashboard</h2>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Container fluid>
                        {successMessage && <Alert variant="success">{successMessage}</Alert>}
                        <Form onSubmit={handleCreateProject}>
                            <Row className="align-items-center mb-4">
                                <Col sm="4">
                                    <Row>
                                        <Col sm="8">
                                            <Form.Group controlId="formProjectName">
                                                <Form.Control
                                                    size="sm"
                                                    className='m-0'
                                                    type="text"
                                                    placeholder="Project Name"
                                                    value={newProjectName}
                                                    onChange={(e) => setNewProjectName(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm="4">
                                            <Button
                                                className='w-100'
                                                size="sm"
                                                variant="primary"
                                                type="submit"
                                                disabled={projectCreating} // Disable button when project is being created
                                            >
                                                {projectCreating ? (
                                                    <>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Creating...
                                                    </>
                                                ) : (
                                                    "Create Project"
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {formError && <Alert variant="danger" className="mt-3">{formError}</Alert>}
                        </Form>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <Container fluid>
                        {loading && (
                            <div className="d-flex justify-content-center my-4">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        )}
                        {!loading && projects.length === 0 && <Alert variant="warning">No projects found.</Alert>}
                        <Row>
                            {projects.map((project) => (
                                <Col md={4} key={project.id} className="mb-4">
                                    <Card bg="light"  text="dark">
                                        <Card.Header>{project.name}</Card.Header>
                                        <Card.Body>
                                            <p className="text-muted">{moment(project.created).format('D MMMM, YYYY')}</p> 
                                           <h4> Latest Report Score: <Badge bg={project.lastReport ? "primary" : "danger"}>{project.lastReport ? "  " + project.lastReport?.score?.totalScore : "No report"}</Badge></h4>
                                            <Button
                                                variant="primary"
                                                onClick={() => handleNavigate(`/organisations/${organisationId}/projects/${project.id}`)}
                                                className="btn-sm">
                                                 <ArrowBarRight className="me-1 mb-1" /> Open Project
                                            </Button>
                                            {project.lastReport && (<>
                                            <Button
                                                variant="primary"
                                                onClick={() => handleNavigate(`/organisations/${organisationId}/projects/${project.id}/reports/${project.lastReport.id}`)}
                                                className="btn-sm m-1">
                                                <Download className="me-1 mb-1" /> Open Latest Report
                                            </Button>
                                            <Button
                                                variant="primary"
                                                disabled
                                                onClick={() => handleNavigate(`/organisations/${organisationId}/projects/${project.id}`)}
                                                className="btn-sm m-1">
                                                <FilePdfFill className="me-1 mb-1" /> Download Latest Report
                                            </Button></>)}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default Dashboard;
