import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from "react-router-dom";
import { Card, Container, Row, Col } from 'react-bootstrap';
import useOrganisation from "../hooks/useOrganisation";
import { useToken } from "../context/TokenContext"; // Use TokenContext for token

const OrganisationDetails = () => {
    const { organisationId } = useParams();
    const { fetchDetails } = useOrganisation();
    const { token } = useToken(); // Access the token from context
    const [organisation, setOrganisation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Memoize the fetch call to avoid re-triggering the useEffect unnecessarily
    const loadOrganisationDetails = useCallback(async () => {
        try {
            const data = await fetchDetails(organisationId, token); // Use token from context
            setOrganisation(data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [organisationId, token]); // Removed fetchDetails from dependency array

    useEffect(() => {
        if (organisationId && token) {
            loadOrganisationDetails();
        }
    }, [organisationId, token, loadOrganisationDetails]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <Container className="mt-4">
            <Row className="justify-content-center">
                <Col md={8}>
                    <Card className="border-primary mb-3">
                        <Card.Header>Organisation Details</Card.Header>
                        <Card.Body>
                            <Card.Title>{organisation?.companyName}</Card.Title>
                            <Card.Text>
                                <strong>Building Number:</strong> {organisation?.buildingNumber}<br />
                                <strong>Postcode:</strong> {organisation?.postcode}<br />
                                <strong>Country:</strong> {organisation?.country}<br />
                                <strong>Phone:</strong> {organisation?.phone || "N/A"}<br />
                                <strong>Website:</strong> {organisation?.website || "N/A"}<br />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default OrganisationDetails;
