import React, { useState, useEffect } from "react";
import { Accordion, Container, Card } from "react-bootstrap";
import ConstraintCategory from "./ConstraintCategory";

export function EnvironmentReports({ landscapeConstraints, landscapeConstraintsPercentage, heritageConstraints, heritageConstraintsPercentage }) {

    return (
        <Container>
            <Card>
                <Accordion className="accordion-clean" flush>
                    <ConstraintCategory
                        eventKey="0"
                        name="Environmental and Landscapes"
                        constraints={landscapeConstraints}
                        percentageComplete={landscapeConstraintsPercentage}
                        percentageBarVariant="landscape"
                    />

                    <ConstraintCategory
                        eventKey="1"
                        name="Heritage"
                        constraints={heritageConstraints}
                        percentageComplete={heritageConstraintsPercentage}
                        percentageBarVariant="environment"
                    />
                </Accordion>
            </Card>

        </Container>
    );
}
