import React from "react";
import { Card, Container, Row, Col, Badge } from "react-bootstrap";
import { ActivityRings } from "@jonasdoesthings/react-activity-rings";
import { Typewriter } from 'react-simple-typewriter';

export function ReportScoreWheel({ reportScore, landscapeConstraintsPercentage, heritageConstraintsPercentage }) {

    const score = reportScore?.totalScore || 0;
    const summary = reportScore?.totalScoreSummary || "Generating Summary....";


    return (
        <Container className="mb-4">
            <Card>
                <Card.Body>
                    <Row className="align-items-center">
                        <Col xs="auto">
                            <ActivityRings
                                rings={[
                                    { filledPercentage: landscapeConstraintsPercentage / 100, color: '#0d184f' },
                                    { filledPercentage: heritageConstraintsPercentage / 100, color: '#25597e' },
                                ]}
                                options={{
                                    containerHeight: '20vh',
                                }}
                            />
                        </Col>
                        <Col>
                            <h1 className="mb-0"><Badge bg="secondary">{score}</Badge></h1>
                        </Col>
                        <Col sm="7">
                            <div >
                                {summary}
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
}