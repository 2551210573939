import React from 'react';
import { Navbar, Nav, Button, Card, Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "../styles/LandingPage.css";
import backgroundVideo from "../videos/newsletter-background3.mp4";
import logo from "../images/logo.png";
import NewsletterSignup from "../components/Newsletter/NewsletterSignup";
import { Typewriter } from 'react-simple-typewriter';
import { Envelope, Linkedin } from "react-bootstrap-icons";

const LandingPage = () => {
    return (
        <>
            {/* Sticky Navbar */}
            <Navbar fixed="top" className="custom-navbar">
                <Navbar.Brand href="#home">
                    <span className="navbar-title">Æ<span className="dot">.</span></span>
                    <img src={logo} alt="Australis Energy Logo" width="40" height="40" className="d-inline-block align-top" />
                </Navbar.Brand>

                <Nav className="ms-auto d-none d-lg-flex">
                    <a href="mailto:hello@australis.energy" title="hello@australis.energy"><Envelope color="#80d1a4" size={24} className="m-2" /> </a>
                    <a href="https://www.linkedin.com/company/australis-energy/about/" target='_blank' title="Linked In"><Linkedin color="#80d1a4" size={24} className="m-2" /> </a>
                    <Nav.Link href="#home" className="nav-link">Home</Nav.Link>
                    <Nav.Link href="#about" className="nav-link">About</Nav.Link>
                    <Nav.Link href="#signup" className="nav-link">Newsletter</Nav.Link>
                    </Nav>

                <div className="navbar-buttons d-lg-none">
                    <Button variant="primary" href="#home" className="nav-button">Home</Button>
                    <Button variant="primary" href="#about" className="nav-button">About</Button>
                    <Button variant="primary" href="#signup" className="nav-button">Newsletter</Button>
                </div>
            </Navbar>

            {/* Full-height Home section with video background */}
            <div id="home" className="section home-section">
                <video autoPlay loop muted playsInline className="background-video">
                    <source src={backgroundVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="content">
                    <h1>
                        <Typewriter
                            words={["Let's Save the World Faster"]}
                            typeSpeed={50}
                            cursor
                        />
                    </h1>
                    <h3 className="fade-in">
                        Accelerate the development of your renewable energy portfolio with Australis
                    </h3>
                </div>
            </div>

            {/* About Section with SwiperJS */}
            <div id="about" className="section about-section">             
                <Card className='stylish-card'>
                    <Card.Body>
                        <Swiper
                            modules={[Navigation, Pagination, Autoplay]}
                            spaceBetween={30}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                            autoplay={{ delay: 5000 }}
                        >
                            <SwiperSlide>
                                <div className="swiper-slide-container">
                                    <div className="swiper-caption">
                                        <h3>Planning Constraints</h3>
                                        <p>Understand the challenges of renewable energy site planning.</p>
                                    </div>
                                    <br/>
                                    <br/>
                                    <img
                                        className="carousel-image"
                                        src={require('../images/landing-info-1.png')}
                                        alt="Newsletter Information 1"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide-container">
                                    <div className="swiper-caption">
                                        <h3>Estimated Power Capacity</h3>
                                        <p>See the power potential of your site.</p>
                                    </div>
                                    <br/>
                                    <br/>
                                    <img
                                        className="carousel-image"
                                        src={require('../images/landing-info-2.png')}
                                        alt="Newsletter Information 2"
                                    />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="swiper-slide-container">
                                    <div className="swiper-caption">
                                        <h3>Grid Connection Availability</h3>
                                        <p>Evaluate grid access for your renewable project.</p>
                                    </div>
                                    <br/>
                                    <br/>
                                    <img
                                        className="carousel-image"
                                        src={require('../images/landing-info-3.png')}
                                        alt="Newsletter Information 3"
                                    />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </Card.Body>
                </Card>

            </div>

            {/* Full-height Signup section */}
            <div id="signup" className="section signup-section">
                <NewsletterSignup />
            </div>
        </>
    );
};

export default LandingPage;
