import React, { useEffect, useState } from 'react';
import { Container, Button, Row, Col, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useToken } from "../context/TokenContext";
import { useParams, useNavigate } from 'react-router-dom';
import * as signalR from '@microsoft/signalr';
import { ReportMap } from '../components/report/ReportMap';
import { EnvironmentReports } from '../components/report/EnvironmentReports';
import { ReportScoreWheel } from '../components/report/ReportScoreWheel';
import { EventConsoleScreen } from '../components/report/EventConsoleScreen';
import { getReport } from '../redux/slices/reportSlice'; // Import the thunk action
import { ReportAdmin } from '../components/project/ProjectAdmin';

const Report = () => {
    const { organisationId, projectId, reportId } = useParams(); // Get reportId from URL
    const { token, loading: tokenLoading, error: tokenError } = useToken();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Local state for the reportBoundary and environment data
    const [environmentData, setEnvironmentData] = useState([]);
    const [eventLog, setEventLog] = useState([]);
    const [scoreSummary, setScoreSummary] = useState(null);
    const [completeMessage, setCompleteMessage] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [polygons, setPolygons] = useState([]);
    const [constraintCoordinates, setConstraintCoordinates] = useState([]);
    const [landscapeConstraints, setLandscapeConstraints] = useState([]);
    const [heritageConstraints, setHeritageConstraints] = useState([]);
    const [landscapeConstraintsPercentage, setLandscapeConstraintsPercentage] = useState([]);
    const [heritageConstraintsPercentage, setHeritageConstraintsPercentage] = useState([]);

    // Fetch the report from Redux
    const report = useSelector((state) => state.reports?.selectedReport);
    const reportLoading = useSelector((state) => state.reports?.loading);
    const reportError = useSelector((state) => state.reports?.error);

    useEffect(() => {
        if (report?.projectBoundary) {
            setPolygons((prevState) => [
                ...prevState,
                { layerId: "projectBoundary", value: report?.projectBoundary, colour: "rgba(255, 0, 0, 0.5)", isBase64Encoded: false }
            ]);

            setLandscapeConstraints(report.calculations["EnvironmentAndLandscapes"] || []);
            setHeritageConstraints(report.calculations["Heritage"] || [])
            setConstraintCoordinates(report.geometries);
            setScoreSummary(report.score);
            if (report.isComplete) {
                setLandscapeConstraintsPercentage(100);
                setHeritageConstraintsPercentage(100);
            }
        }
    }, [report]); // This runs only when `report` changes

    // Fetch the report when component mounts
    useEffect(() => {
        if (reportId && token) {
            setIsLoading(true); // Show loading spinner
            dispatch(getReport({ reportId, token }))
                .then(() => setIsLoading(false)) // Hide spinner after fetching
                .catch(() => setIsLoading(false)); // Ensure spinner is hidden on error too
        }
    }, [dispatch, reportId, token]);

    // SignalR connection setup to receive live updates
    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_API_BACKEND_URL}reportHub`)
            .configureLogging(signalR.LogLevel.Error)
            .build();

        connection.on("calculationCompleteMessage_" + reportId, (data) => {
            setCompleteMessage((prevState) => [...prevState, data]);
        });

        connection.on("scoreSummary_" + reportId, (data) => {
            setScoreSummary(data);
        });

        connection.on("mapData_" + reportId, (data) => {
            if (data.type == "MapLayer") {
                setPolygons({ layerId: data.layerId, value: data.geoJson, colour: data.layerRGBValue, isBase64Encoded: true });
            }
        });

        connection.on("mapCoordinates_" + reportId, (data) => {
            setConstraintCoordinates((prevState) => [...prevState, data]);
        });

        connection.on(`landscapeConstraints_${reportId}`, (data) => {
            console.log('landscapeConstraints_${reportId}', data);
            setLandscapeConstraints((prevState) => [...prevState, data]);
        });

        connection.on(`heritageConstraints_${reportId}`, (data) => {
            setHeritageConstraints((prevState) => [...prevState, data]);
        });

        connection.on(`landscapeConstraintsPercentage_${reportId}`, (data) => {
            setLandscapeConstraintsPercentage(data.percentageComplete);
        });

        connection.on(`heritageConstraintsPercentage_${reportId}`, (data) => {
            setHeritageConstraintsPercentage(data.percentageComplete);
        });

        connection.on("eventLog_" + reportId, (data) => {
            setEventLog((prevState) => [...prevState, data]);
        });

        connection.start()
            .catch(err => console.error("SignalR Connection Error: ", err));

        return () => {
            connection.stop()
                .catch(err => console.error("Error disconnecting from SignalR hub: ", err));
        };
    }, [reportId]);

    // Handle loading and error states
    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    if (reportError) {
        return <p>Error loading report: {reportError}</p>;
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <h2>{report?.projectName}</h2> <h5>{report?.name}</h5>
                        <hr />
                        <Button variant="secondary" className='mb-4' href={`/organisations/${organisationId}/projects/${projectId}`} >Back to Project</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <ReportMap polygons={polygons} constraintCoordinates={constraintCoordinates} />
                            </Col>
                        </Row>
                        {!report?.isComplete &&
                            <>
                                <Row>

                                    <Col>
                                        <EventConsoleScreen data={eventLog} />
                                    </Col>
                                </Row>
                            </>
                        }
                    </Col>
                    <Col>
                        <Row>
                            <Col><ReportScoreWheel
                                reportScore={scoreSummary}
                                landscapeConstraintsPercentage={landscapeConstraintsPercentage}
                                heritageConstraintsPercentage={heritageConstraintsPercentage}
                            />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <EnvironmentReports
                                    landscapeConstraints={landscapeConstraints}
                                    landscapeConstraintsPercentage={landscapeConstraintsPercentage}

                                    heritageConstraints={heritageConstraints}
                                    heritageConstraintsPercentage={heritageConstraintsPercentage}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Report;
