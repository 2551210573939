import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getProjects, getProjectById, createProject as apiCreateProject, saveProject as apiSaveProject, deleteProject as apiDeleteProject } from '../../api/projectApi';

// Fetch all projects
export const fetchProjects = createAsyncThunk(
    'projects/fetchProjects',
    async ({ organisationId, token }, { rejectWithValue }) => {
        try {
            const projects = await getProjects(organisationId, token); // Pass the token to the API call
            return projects;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchProject = createAsyncThunk(
    'projects/fetchProject',
    async ({ projectId, token }, { rejectWithValue }) => {
        try {
            const project = await getProjectById(projectId, token); // Pass the token to the API call
            return project;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const createProject = createAsyncThunk(
    'projects/createProject',
    async ({ projectData, token }, { rejectWithValue }) => {
        try {
            const newProject = await apiCreateProject(projectData, token); // Pass the token to the API call
            return newProject;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const saveProject = createAsyncThunk(
    'projects/saveProject',
    async ({ projectData, token }, { rejectWithValue }) => {
        try {
            const project = await apiSaveProject(projectData, token); // Pass the token to the API call
            return project;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Delete a project by ID
export const deleteProject = createAsyncThunk(
    'projects/deleteProject',
    async ({ projectId, token }, { rejectWithValue }) => {
        try {
            await apiDeleteProject(projectId, token);
            return projectId;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const projectSlice = createSlice({
    name: 'projects',
    initialState: {
        projects: [],
        selectedProject: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjects.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.loading = false;
                state.projects = action.payload;
            })
            .addCase(fetchProjects.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchProject.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchProject.fulfilled, (state, action) => {
                state.loading = false;
                state.selectedProject = action.payload;
            })
            .addCase(fetchProject.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createProject.fulfilled, (state, action) => {
                state.projects.push(action.payload);
            })
            .addCase(saveProject.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(saveProject.fulfilled, (state, action) => {
                state.loading = false;

                // Update the project in the projects array if it already exists
                const index = state.projects.findIndex(
                    (project) => project.ProjectId === action.payload.ProjectId
                );
                if (index !== -1) {
                    state.projects[index] = action.payload;
                } else {
                    state.projects.push(action.payload);
                }

                // Update selectedProject if it matches the saved one
                if (
                    state.selectedProject &&
                    state.selectedProject.ProjectId === action.payload.ProjectId
                ) {
                    state.selectedProject = action.payload;
                }
            })
            .addCase(saveProject.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                state.loading = false;
                state.selectedProject = null;
            });
    },
});


export default projectSlice.reducer;
