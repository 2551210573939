import React from 'react';
import useMap from '../hooks/useMap';
import { Card, Spinner, Alert } from "react-bootstrap";
import { useSelector } from 'react-redux';

const PolygonInformation = () => {
    const project = useSelector((state) => state.project.selectedProject);
    const loading = useSelector((state) => state.project.loading); // Check loading state from Redux
    const error = useSelector((state) => state.project.error); // Check error state from Redux

    const { getPolygonDetails } = useMap();
    const data = getPolygonDetails();

    // Render loading spinner
    if (loading) {
        return (
            <Card>
                <Card.Body>
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                </Card.Body>
            </Card>
        );
    }

    // Render error message
    if (error) {
        return (
            <Alert variant="danger" className="text-center">
                <strong>Error:</strong> {error}
            </Alert>
        );
    }

    // Render "No Data" if project or data is null
    if (!project || !data) {
        return (
            <Card>
                <Card.Body>
                    <Alert variant="warning" className="text-left">
                        <p>Project boundary not loaded.</p>
                        <span className="text-muted small">
                            Please drag & drop your project file onto the map. Allowed file types: *.json, *.kmz, *.kml
                        </span>
                    </Alert>
                </Card.Body>
            </Card>

        );
    }

    // Render polygon details
    return (
        <Card>
            <Card.Header>Polygon Information</Card.Header>
            <Card.Body>
                <strong>City: </strong>{data?.City || 'N/A'}<br />
                <strong>County: </strong>{data?.County || 'N/A'}<br />
                <strong>Country: </strong>{data?.Country || 'N/A'}<br />
                <hr />
                <strong>Type:</strong> {data?.Type || 'N/A'}<br />
                <strong>Area:</strong>{' '}
                {data?.AreaAsString
                    ? `${(parseFloat(data.AreaAsString) / 10000).toFixed(4)} ha`
                    : 'N/A'}
                <br />

                <strong>Perimeter:</strong> {data?.PerimeterAsString || 'N/A'}
            </Card.Body>
        </Card>
    );
};

export default PolygonInformation;
