import React, { useEffect } from "react";
import { Button, Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import logo from "../../images/logo.png";
import useUserAccount from "../../hooks/useUserAccount";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchNotifications } from "../../redux/slices/notificationSlice"; // Import action to fetch notifications
export function TopHeader() {
    const navigate = useNavigate();
    const { login, logout, isAuthenticated } = useUserAccount();
    const dispatch = useDispatch();

    const userData = useSelector((state) => state.user.userData) || {};
    const organisationId = userData?.organisationId;

    useEffect(() => {
        if (isAuthenticated() && userData?.oid) {
            dispatch(fetchNotifications(userData.oid)); // Fetch notifications after login
        }
    }, [dispatch, isAuthenticated, userData]);

    const handleNavigate = (path) => {
        navigate(path);
    };

    return (<>
        <Navbar bg="primary" variant="dark" expand="lg">
            <Container fluid>
                <Nav className="me-auto d-flex align-items-center">
                    <Navbar.Brand href="/">
                        <span className="navbar-title">Æ<span className="dot">.</span></span>
                        <img
                            src={logo}
                            alt="Australis Energy Logo"
                            width="40"
                            height="40"
                            className="d-inline-block align-top"
                        />{" "}
                    </Navbar.Brand>
                    {isAuthenticated() && organisationId && (
                        <>
                            <Button variant="light" href={`/organisations/${organisationId}/dashboard`} >Dashboard</Button>

                        </>
                    )}
                </Nav>
                <Nav>
                    {isAuthenticated() ? (
                        <>

                            <Dropdown align="end" className="p-1">
                                <Dropdown.Toggle variant="secondary" className="btn-sm">
                                    {userData?.email || 'User'}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {organisationId && (
                                        <Dropdown.Item onClick={() => handleNavigate(`/organisations/${organisationId}/details`)}>
                                            Organisation Details
                                        </Dropdown.Item>
                                    )}
                                    <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                                </Dropdown.Menu>

                            </Dropdown>
                        </>
                    ) : (
                        <Button variant="secondary" onClick={login}>
                            Login
                        </Button>
                    )}
                </Nav>
            </Container>
        </Navbar>
    </>
    );
}
