import React from "react";
import { Accordion, Badge, ProgressBar } from "react-bootstrap";

const ConstraintCategory = ({ eventKey, name, constraints, percentageComplete, percentageBarVariant}) => { 
  
  const enabledConstraints = constraints?.filter(
    (report) => report.score !== 100
  ) || [];
  const disabledConstraints = constraints?.filter(
    (report) => report.score === 100
  ) || [];

  const isInProgress = percentageComplete < 100;

  return (
    <Accordion.Item eventKey={eventKey} className="accordion-item-clean">
      <Accordion.Header>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <span style={{ flex: 1 }}>{name}</span>
          <ProgressBar
            animated={isInProgress}
            striped={isInProgress}
            variant={percentageBarVariant}
            now={percentageComplete}
            style={{ flex: 2, marginLeft: "1em", marginRight: "2em" }}
          />
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <Accordion>
          {/* Render Enabled Constraints */}
          {enabledConstraints.map((report, index) => (
            <Accordion.Item eventKey={`enabled-${index}`} key={`enabled-${index}`}>
              <Accordion.Header>
                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <span style={{ flex: 1 }}>
                    {report.name}
                  </span>
                  <h5>  <Badge style={{ marginRight: "1rem" }} className="ms-2 bg-danger">{report.score}</Badge></h5>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <>
                  Closest Location:{" "}
                  {report.closestLocationDetails.name || "Closest Location not available"}
                  <br />
                  Distance from boundary:{" "}
                  {report.closestLocationDetails.minDistanceMiles ||
                    "Distance from boundary not available"}
                  m
                  <br />
                  <hr />
                  <b>Our Assessment</b>
                  <br />
                  <p>
                    The location at latitude 51.5074 and longitude -0.1278 is 0.5 miles north
                    of {report.closestLocationDetails.name}, a Grade II listed building. Its
                    closeness poses challenges for construction due to strict preservation
                    regulations and potential impact on the building’s historic setting.
                  </p>
                  <br />
                  <hr />
                </>
              </Accordion.Body>
            </Accordion.Item>
          ))}

          {/* Render Disabled Constraints */}
          {disabledConstraints.map((report, index) => (
            <Accordion.Item
              eventKey={`disabled-${index}`}
              key={`disabled-${index}`}
              className="disabled-accordion-item"
            >
              <Accordion.Header>
                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <span style={{ flex: 1, color: "gray" }}>
                    {report.name}
                  </span>
                  <h5><Badge style={{ marginRight: "1rem" }} className="ms-2 bg-primary">{report.score}</Badge></h5>
                </div>
              </Accordion.Header>
            </Accordion.Item>
          ))}
        </Accordion>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default ConstraintCategory;
