import React, { useEffect, useState } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProject } from '../redux/slices/projectSlice';
import AzureMapComponent from '../components/project/AzureMapComponent';
import PolygonInformation from '../components/PolygonInformation';
import ReportsList from '../components/project/ReportsList';
import ProjectAdmin from '../components/project/ProjectAdmin';
import { useToken } from '../context/TokenContext';

const ProjectDetails = () => {
    const { organisationId, projectId } = useParams();
    const dispatch = useDispatch();
    const { token, loading } = useToken();

    const project = useSelector((state) => state.project.selectedProject);
    const error = useSelector((state) => state.project.error);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const totalReports = project?.reports?.length || 0;
    const totalPages = Math.ceil(totalReports / itemsPerPage);

    const paginatedReports = project?.reports?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    useEffect(() => {
        if (!loading && token && organisationId && projectId) {
            dispatch(fetchProject({ projectId, token }));
        }
    }, [dispatch, organisationId, projectId, token, loading]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h4>{project?.name || 'Project Details'}</h4>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card style={{ height: '100%' }}>
                        <Card.Body>
                            {project && <AzureMapComponent project={project} />}
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Row>
                        <Col xs={6}>
                            <PolygonInformation project={project} />
                        </Col>
                        <Col xs={6}>
                            <ReportsList
                                reports={paginatedReports}
                                project={project}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPreviousPage={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                onNextPage={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                isLoading={loading}
                            />
                            <ProjectAdmin project={project} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default ProjectDetails;
