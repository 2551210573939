import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import * as atlas from 'azure-maps-control';
import { kml as convertKML } from '@tmcw/togeojson';
import JSZip from 'jszip';
import mapConfig from '../../config/mapConfig';
import { Container, Alert, Button } from 'react-bootstrap';
import { useToken } from "../../context/TokenContext";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveProject } from '../../redux/slices/projectSlice';
import { saveReport } from '../../redux/slices/reportSlice';

const AzureMapComponent = (props) => {
    const project = props.project;
    const projectId = project?.id || null;
    const organisationId = project?.organisationId || null;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mapRef = useRef(null);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [clickedPolygon, setClickedPolygon] = useState(null);
    const [map, setMap] = useState(null);
    const [datasource, setDatasource] = useState(null);
    const { token } = useToken();

    console.log("AzureMapComponent", project?.id);

    const initializeMap = () => {
        if (mapRef.current) {
            const newMap = new atlas.Map(mapRef.current, mapConfig);
            newMap.events.add('ready', () => {
                const newDatasource = new atlas.source.DataSource();
                newMap.sources.add(newDatasource);
                addPolygonLayer(newMap, newDatasource);
                addEventListeners(newMap, newDatasource);
                setDatasource(newDatasource);
                setMap(newMap);
            });
            return newMap;
        }
        return null;
    };

    const addPolygonLayer = (map, datasource) => {
        const polygonLayer = new atlas.layer.PolygonLayer(datasource, null, {
            fillColor: '#E32E2E',
            strokeColor: '#000000',
            strokeWidth: 2,
        });
        map.layers.add(polygonLayer);

        map.events.add('click', polygonLayer, (e) => {
            if (e.shapes && e.shapes.length > 0) {
                const polygonData = e.shapes[0];
                setClickedPolygon(polygonData);
                setIsButtonEnabled(true);
            }
        });
    };

    const addEventListeners = (map, datasource) => {
        mapRef.current.addEventListener('dragover', handleDragOver);
        mapRef.current.addEventListener('drop', (event) => handleFileDrop(event, datasource, map));
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';
    };

    const handleFileDrop = async (event, datasource, map) => {
        event.preventDefault();
        datasource.clear();

        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];
            const fileName = file.name.toLowerCase();

            if (fileName.endsWith('.geojson') || fileName.endsWith('.json')) {
                await handleGeoJSON(file, datasource, map);
            } else if (fileName.endsWith('.kml')) {
                await handleKML(file, datasource, map);
            } else if (fileName.endsWith('.kmz')) {
                await handleKMZ(file, datasource, map);
            } else {
                alert('Unsupported file format. Please upload a GeoJSON, JSON, KML, or KMZ file.');
            }
        }
    };

    const handleGeoJSON = async (file, datasource, map) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const geojsonData = JSON.parse(e.target.result);
                addDataToMap(datasource, map, geojsonData);
                await savePolygonDataWithToken(geojsonData);
            } catch (error) {
                alert('Invalid GeoJSON or JSON file.');
            }
        };
        reader.readAsText(file);
    };

    const handleKML = async (file, datasource, map) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const parser = new DOMParser();
                const kmlDocument = parser.parseFromString(e.target.result, 'application/xml');

                const parserError = kmlDocument.getElementsByTagName('parsererror');
                if (parserError.length > 0) {
                    console.error('Error parsing KML:', parserError[0].textContent);
                    alert('Invalid KML file format.');
                    return;
                }

                const geoJson = convertKML(kmlDocument);

                if (!geoJson || !geoJson.type) {
                    console.error('Invalid GeoJSON from KML:', geoJson);
                    alert('Invalid KML file format. The conversion to GeoJSON failed.');
                    return;
                }

                const validFeatures = geoJson.features.filter(feature => feature.geometry !== null);

                if (validFeatures.length === 0) {
                    console.warn('No valid geometries found in the KML file.');
                    alert('No valid geometries found in the KML file.');
                    return;
                }

                geoJson.features = validFeatures;
                addDataToMap(datasource, map, geoJson);
                await savePolygonDataWithToken(geoJson);
            } catch (error) {
                console.error('Error processing KML file:', error);
                alert('Invalid KML file.');
            }
        };
        reader.readAsText(file);
    };

    const handleKMZ = async (file, datasource, map) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const zip = await JSZip.loadAsync(e.target.result);
                const kmlFile = zip.file(/\.kml$/i)[0];
                if (kmlFile) {
                    const kmlText = await kmlFile.async('text');
                    const kmlDocument = new DOMParser().parseFromString(kmlText, 'application/xml');
                    const geoJson = convertKML(kmlDocument);

                    addDataToMap(datasource, map, geoJson);
                    await savePolygonDataWithToken(geoJson);
                } else {
                    alert('Invalid KMZ file. No KML found.');
                }
            } catch (error) {
                alert('Error processing KMZ file.');
            }
        };
        reader.readAsArrayBuffer(file);
    };

    const addDataToMap = (datasource, map, geojsonData) => {
        let parsedData;

        try {
            parsedData = typeof geojsonData === 'string' ? JSON.parse(geojsonData) : geojsonData;
        } catch (error) {
            console.error('Failed to parse GeoJSON data:', error);
            return;
        }

        if (!parsedData || !parsedData.type) {
            console.error('Invalid GeoJSON data:', parsedData);
            return;
        }

        switch (parsedData.type) {
            case 'FeatureCollection':
                datasource.add(parsedData);
                break;
            case 'Feature':
                datasource.add(parsedData);
                break;
            case 'Polygon':
            case 'MultiPolygon':
            case 'LineString':
            case 'MultiLineString':
            case 'Point':
            case 'MultiPoint':
                datasource.add({
                    type: 'Feature',
                    geometry: parsedData,
                    properties: {},
                });
                break;
            default:
                console.error('Unsupported GeoJSON type:', parsedData.type);
                return;
        }

        const bounds = atlas.data.BoundingBox.fromData(parsedData);
        if (bounds) {
            map.setCamera({ bounds, padding: 50 });
        }
    };

    const savePolygonDataWithToken = async (geojsonData) => {
        try {
            await dispatch(saveProject({
                projectData: { ProjectId: projectId, OrganisationId: organisationId, polygonData: JSON.stringify(geojsonData) },
                token,
            }));
            setSuccessMessage("Polygon data saved successfully.");
        } catch (error) {
            setError(error.message);
        }
    };

    const renderButton = () => (
        <Button
            variant={isButtonEnabled ? 'secondary' : 'dark'}
            className="w-100 mb-1"
            onClick={() => {
                if (isButtonEnabled) {
                    dispatch(saveReport({ reportData: { projectId, organisationId }, token }))
                        .unwrap()
                        .then((response) => {
                            const reportId = response.reportId;
                            navigate(`/organisations/${organisationId}/projects/${projectId}/reports/${reportId}`);
                        })
                        .catch(console.error);
                }
            }}
            disabled={!isButtonEnabled}
        >
            Run Report
        </Button>
    );

    useLayoutEffect(() => {
        if (project) {
            const mapInstance = initializeMap();
            return () => {
                if (mapInstance) {
                    mapInstance.dispose();
                    mapRef.current.removeEventListener('dragover', handleDragOver);
                    mapRef.current.removeEventListener('drop', handleFileDrop);
                }
            };
        }
    }, [project]);

    useEffect(() => {
        if (map && datasource && project?.projectBoundary) {
            addDataToMap(datasource, map, project.projectBoundary);
        }
    }, [project, map, datasource]);

    return (
        <Container>
            {renderButton()}
            {error && <Alert variant="danger">{error}</Alert>}
            <div ref={mapRef} style={{ height: '700px', width: '100%' }} />
        </Container>
    );
};

export default AzureMapComponent;
