import React from 'react';
import { Card, ListGroup, Badge, Button, Spinner } from 'react-bootstrap';
import { BoxArrowRight } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

const ReportsList = ({
    reports = [],
    project,
    currentPage,
    totalPages,
    onPreviousPage,
    onNextPage,
    isLoading,
}) => {
    const navigate = useNavigate();

    if (isLoading) {
        return (
            <div className="text-center my-3">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <Card>
            <Card.Header>Reports</Card.Header>
            <Card.Body>
                <ListGroup>
                    {reports.map((report, index) => (
                        <ListGroup.Item
                            key={index}
                            className="d-flex justify-content-between align-items-center"
                        >
                            <div className="d-flex align-items-center">
                                <Button
                                    variant="link"
                                    className="p-0 me-2"
                                    onClick={() =>
                                        navigate(`/organisations/${project.organisationId}/projects/${project.id}/reports/${report.id}`)
                                    }
                                >
                                    <BoxArrowRight />
                                </Button>
                                <div className="small-text">
                                    {report.name.length > 50
                                        ? `${report.name.substring(0, 50)}...`
                                        : report.name}
                                </div>
                                <span className="text-muted ms-2">{report.author}</span>
                            </div>
                            <Badge bg="primary" pill>
                                {report.score?.totalScore}
                            </Badge>
                        </ListGroup.Item>
                    ))}
                </ListGroup>

                <div className="d-flex justify-content-between align-items-center mt-3">
                    <Button
                        variant="secondary"
                        disabled={currentPage === 1}
                        onClick={onPreviousPage}
                    >
                        Previous
                    </Button>
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                    <Button
                        variant="secondary"
                        disabled={currentPage === totalPages || reports.length === 0}
                        onClick={onNextPage}
                    >
                        Next
                    </Button>
                </div>
            </Card.Body>
        </Card>
    );
};

export default ReportsList;
