import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import * as atlas from 'azure-maps-control';
import mapConfig from '../../config/mapConfig';
import { addLayer, setCameraView, getCentroidByLayerId, drawLine, AddGeometry } from '../../helpers/mapHelpers';
import 'azure-maps-control/dist/atlas.min.css';
import { wktToGeoJSON } from '@terraformer/wkt';


export function ReportMap(props) {
    const layerData = props.polygons[0];
    const constraintCoordinates = props.constraintCoordinates;

    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    const [polygonDatasource, setPolygonDatasource] = useState(null);
    const [lineDatasource, setLineDatasource] = useState(null);

    useEffect(() => {
        const loadAndAddProjectBoundary = async () => {
            if (layerData && map && polygonDatasource && lineDatasource) {
                addLayer(layerData, map, polygonDatasource);
                setCameraView(map, polygonDatasource);
    
                // Return the centroid of the project layer
                return getCentroidByLayerId(layerData.layerId + "-0", polygonDatasource);
            }
        };
    
        const loadAndAddConstraintGeometries = async (projectCentroid) => {
            if (map && polygonDatasource && lineDatasource && constraintCoordinates) {
                constraintCoordinates.forEach(constraint => {
                    AddGeometry(map, polygonDatasource, constraint);
    
                    // Parse WKT centroid to GeoJSON and extract coordinates
                    let pointA = null;
                    try {
                        const geoJson = wktToGeoJSON(constraint.centroid);
                        if (geoJson.type === "Point") {
                            pointA = geoJson.coordinates; // [longitude, latitude]
                        }
                    } catch (error) {
                        console.error("Invalid WKT centroid:", constraint.centroid, error);
                    }
    
                    const pointB = projectCentroid; // Ensure projectCentroid is already computed
    
                    if (pointA && pointB) {
                        drawLine(pointA, pointB, lineDatasource, map);
                    } else {
                        console.error("Invalid points for drawing a line:", pointA, pointB);
                    }
                });
    
                setCameraView(map, polygonDatasource);
            }
        };
    
        // Async wrapper to handle awaiting
        const loadData = async () => {
            const projectCentroid = await loadAndAddProjectBoundary();
            if (projectCentroid) {
                await loadAndAddConstraintGeometries(projectCentroid);
            }
        };
    
        loadData();
    }, [layerData, map, polygonDatasource, lineDatasource, constraintCoordinates]);
    

    const initializeMap = () => {
        if (mapRef.current) {
            const newMap = new atlas.Map(mapRef.current, mapConfig);
            newMap.events.add('ready', () => {
                const newPolygonDatasource = new atlas.source.DataSource();
                const newLineDatasource = new atlas.source.DataSource();
                newMap.sources.add(newPolygonDatasource);
                newMap.sources.add(newLineDatasource);
                setPolygonDatasource(newPolygonDatasource);
                setLineDatasource(newLineDatasource);
                setMap(newMap);
            });
        }
    };

    useLayoutEffect(() => {
        initializeMap();
    }, []);

    return (
        <Card>
            <Card.Body>
                <Container>
                    <div ref={mapRef} className="azure-map-container" style={{ height: '500px', width: '100%' }}></div>
                </Container>
            </Card.Body>
        </Card>
    );
}
